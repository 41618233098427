import { useEffect } from 'react'
import { useRouter } from 'next/router'
import Script from 'next/script'
import { GoogleAnalytics } from "nextjs-google-analytics";
import posthog from 'posthog-js'
import { PostHogProvider } from 'posthog-js/react'
import initAuth from '../initAuth'
import '../styles/globals.css'
import 'tailwindcss/tailwind.css'
import { CompanyProvider } from '../contexts/CompanyContext';

initAuth()

// Check that PostHog is client-side (used to handle Next.js SSR)
if (typeof window !== 'undefined') {
  posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY, {
    api_host: process.env.NEXT_PUBLIC_POSTHOG_HOST || 'https://app.posthog.com',
    // Disable in development
    loaded: (posthog) => {
      if (process.env.NODE_ENV === 'development') posthog.opt_out_capturing()
    }
  })
}



function Pinto({ Component, pageProps }) {
  const router = useRouter()

  useEffect(() => {
    // Track page views
    const handleRouteChange = () => posthog?.capture('$pageview')
    router.events.on('routeChangeComplete', handleRouteChange)

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router])

  return (
    <PostHogProvider client={posthog}>
      <CompanyProvider>
      <Component {...pageProps} />
      </CompanyProvider>
      <GoogleAnalytics trackPageViews /> 
      <Script lazyOnload id="linkedin">
        {`
          _linkedin_partner_id = "5389892";
          window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
          window._linkedin_data_partner_ids.push(_linkedin_partner_id);
          
          (function(l) {
if (!l){window.lintrk = function (a, b) { window.lintrk.q.push([a, b]) };
window.lintrk.q=[]}
          var s = document.getElementsByTagName("script")[0];
          var b = document.createElement("script");
          b.type = "text/javascript";b.async = true;
          b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
s.parentNode.insertBefore(b, s);})(window.lintrk);
        `} 
      </Script> 
    </PostHogProvider>
  )
}

export default Pinto
